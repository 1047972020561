import React from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";

import {
  FRONTEND_LOGIN_URL,
  FRONTEND_SIGNUP_URL,
} from "../../../common/utils/constants";
import useUserContext from "../../../common/context/get-user/useUserContext";
import Logo from "./Logo";
import SearchApps from "./SearchApps";
import { neutral } from "../../../common/config/colors";

const DownMdDrawer = dynamic(() => import("./DownMdDrawer"));
const ProfileAvatar = dynamic(() => import("./ProfileAvatar"));

const Header = () => {
  const router = useRouter();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useUserContext();

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        boxShadow: "none",
        my: 4,
      }}
    >
      <Toolbar>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateAreas: `
                    "logo buttons"
                    "search search"
                `,
                rowGap: 4,
              },
            }}
          >
            <Box sx={{ gridArea: "logo", "& a": { textDecoration: "none" } }}>
              <Link href="/apps">
                <a>
                  <Logo sx={{ width: { xs: 142, md: 207 }, height: 44 }} />
                </a>
              </Link>
            </Box>
            <Box sx={{ gridArea: "search", width: { xs: "100%", md: "50%" } }}>
              <SearchApps />
            </Box>
            <Box
              sx={{
                gridArea: "buttons",
                "& a": {
                  textDecoration: "none",
                },
                [theme.breakpoints.down("md")]: {
                  justifySelf: "end",
                },
              }}
            >
              {!isDownMd && (
                <Link href={`https://aiplanet.com/lp/book-a-demo`}>
                  <a>
                    <Button variant="contained">Book Demo</Button>
                  </a>
                </Link>
              )}
              {!!user ? (
                <ProfileAvatar />
              ) : isDownMd ? (
                <DownMdDrawer />
              ) : (
                <>
                  <Link href={`${FRONTEND_LOGIN_URL}?next=${router.asPath}`}>
                    <a>
                      <Button sx={{ color: neutral[900] }}>Login</Button>
                    </a>
                  </Link>
                  <Link href={`${FRONTEND_SIGNUP_URL}?next=${router.asPath}`}>
                    <a>
                      <Button variant="contained">Sign up</Button>
                    </a>
                  </Link>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
